export const items = [

  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    to: "/dashboard"
  },
  {
    title: "Stat Graphe",
    icon: "mdi-chart-bar-stacked",
    group: "charts",
    children: [
      
      {
        title: "Niveau Departemental",
        icon: "mdi-circle-medium",
        to: "niveau-departement"
      },
      {
        title: "Niveau Communale",
        icon: "mdi-circle-medium",
        to: "niveau-communale"
      },
      {
        title: "Niveau Pôle",
        icon: "mdi-circle-medium",
        to: "niveau-pole"
      },

      {
        title: "Période",
        icon: "mdi-circle-medium",
        to: "niveau-periode"
      },
     
    ]
  },

 /* {
    title: "Stat Tableau",
    icon: "mdi-table",
    group: "table",
    children: [
       {
        title: "Niveau Departemental",
        icon: "mdi-circle-medium",
        to: "#"
      },
      {
        title: "Niveau Communale",
        icon: "mdi-circle-medium",
        to: "#"
      },
    ]
        
  },*/

  {
    title: "Cartographie",
    icon: "mdi-map-marker",
    group: "cartographie",
    children: [
     /* {
        title: "Niveau Departemental",
        icon: "mdi-circle-medium",
        to: "cartographie"
      },
      {
        title: "Niveau Communale",
        icon: "mdi-circle-medium",
        to: "cartographie2"
      },*/
      {
        title: "Station Pluviometre",
        icon: "mdi-circle-medium",
        to: "map-station-pluviometre"
      },
    ]

  },

  {
    title: "Station pluviometre",
    icon: "mdi-weather-pouring",
    to: "/app/station-pluviometre"
  },
  {
    title: "Collecte de données",
    icon: "mdi-database-arrow-right-outline",
    to: "/app/collecte-donnees"
  },
  {
    title: "Institution",
    icon: "mdi-sitemap",
    group: "institution",
    children: [
      {
        title: "DDAEP",
        icon: "mdi-circle-medium",
        to: "ddaep"
      },
      {
        title: "ATDA",
        icon: "mdi-circle-medium",
        to: "atda"
      }
    ]
  },

  {
    title: "Parametre",
    icon: "mdi-cog",
    group: "parametre",
    children: [
      
      {
        title: "Departement",
        icon: "mdi-circle-medium",
        to: "departement"
      },
      {
        title: "Commune",
        icon: "mdi-circle-medium",
        to: "commune"
      },
      {
        title: "Arrondissement",
        icon: "mdi-circle-medium",
        to: "arrondissement"
      },
      {
        title: "Village/quartier",
        icon: "mdi-circle-medium",
        to: "village"
      },
    
    ]
  },
  {
    title: "Account",
    icon: "mdi-account",
    group: "account",
    children: [
      {
        title: "ADMINISTRATEUR",
        icon: "mdi-circle-medium",
        to: "account-administrateur"
      },
      {
        title: "Compte admin DDAEP",
        icon: "mdi-circle-medium",
        to: "account-admin-ddaep"
      },
     
      {
        title: "Compte admin ATDA",
        icon: "mdi-circle-medium",
        to: "account-admin-atda"
      },
      {
        title: "Compte Agent DDAP",
        icon: "mdi-circle-medium",
        to: "account-agent-ddaep"
      },
      
    ]
  },

  
];
